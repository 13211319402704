<script>
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'

  import Footer from '@/components/landing-page/Footer.svelte'
  import Hero from '@/pages/scorpio/components/io/01-1Hero.svelte'
  import AsSeenOn from '@/pages/scorpio/components/io/02AsSeenOn.svelte'
  import Quote from '@/pages/scorpio/components/io/03Quote.svelte'

  import HowItWorks from '@/pages/scorpio/components/io/06HowItWorks.svelte'
  import CompatibilityWidget from '@/pages/scorpio/components/io/07CompatibilityWidget.svelte'
  import BuyBox from '@/pages/scorpio/components/io/08BuyBox.svelte'

  import FAQ from '@/pages/scorpio/components/io/11FAQ.svelte'
  import About from '@/pages/scorpio/components/io/12About.svelte'

  import Everything from '@/pages/scorpio/components/io/08Everything.svelte'

  import Benefits from '@/pages/scorpio/components/io/Benefits.svelte'

  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  export let funnel
  export let gateway
</script>

<main class="md:pb-0">
  <Hero>
    <div class="flex justify-center md:justify-start w-full" slot="cta">
      <div class="max-w-max">
        <JumpToBuyBoxButton size="full">
          <span class="uppercase text-2xl">try FIXD FOR $19.99</span>
        </JumpToBuyBoxButton>
      </div>
    </div>
  </Hero>

  <AsSeenOn invert={true} />

  <div class="max-w-[1440px] mx-auto mt-4 md:mt-10">
    <h1 class="md:hidden font-extrabold text-center mb-4">
      Try FIXD And Get $388 In Money-Saving Car Features For Only $19.99!
    </h1>
    <BuyBox {funnel} {gateway} />
  </div>

  <Quote
    header={'"Paid for itself the first time I used it."'}
    showQuotation={false}
  >
    <p slot="description" class="font-medium text-xl">
      Mark Michael, <span class="italic">Verified Buyer</span>
    </p>
  </Quote>

  <Benefits>
    <div class="flex justify-center w-full mt-10" slot="cta">
      <div class="max-w-max">
        <JumpToBuyBoxButton size="full">
          <span class="uppercase text-xl">Try fixd for $19.99</span>
        </JumpToBuyBoxButton>
      </div>
    </div>
  </Benefits>

  <Everything>
    <div class="flex justify-center w-full" slot="cta">
      <div class="max-w-max">
        <JumpToBuyBoxButton size="full">
          <span class="uppercase text-xl">Try fixd for $19.99</span>
        </JumpToBuyBoxButton>
      </div>
    </div>
  </Everything>
  <HowItWorks>
    <div class="flex justify-center w-full" slot="cta">
      <div class="max-w-max">
        <JumpToBuyBoxButton size="full">
          <span class="uppercase text-xl">Get $230+ in savings</span>
        </JumpToBuyBoxButton>
      </div>
    </div>
  </HowItWorks>

  <CompatibilityWidget>
    <h1 class="font-extrabold" slot="header">
      Wondering If FIXD Works With Your Car?
    </h1>

    <div slot="content" class="my-10 md:mb-16">
      <p class="font-medium">
        FIXD works with all gas-powered cars and trucks <br
          class="hidden md:block"
        />built after 1996 and diesels built after 2008.
      </p>
    </div>
    <JumpToBuyBoxButton slot="compatible" maxWidth={56}>
      <span class="uppercase">BUY FIXD NOW</span>
    </JumpToBuyBoxButton>
  </CompatibilityWidget>

  <LiveReviews backgroundColor="white" />

  <FAQ background="bg-grey-200" />

  <About />
  <Footer />
</main>
